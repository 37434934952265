<template>
  <v-container fluid>
    <div class="main">

      <v-row v-if="can('admin | order_manager | supplier')">
        <v-col cols="12" lg="3">
          <v-card class="shadow rounded-lg">
            <v-card-text class="py-6">
              <v-row align="center">
                <v-col cols="8">
                  <p class="text-no-wrap mb-0">
                    Montant total
                  </p>
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-icon color="gifty" size="30">mdi-currency-eur</v-icon>
                </v-col>
              </v-row>
              <v-skeleton-loader width="50" v-if="totalAmountLoading" type="text"/>
              <h3 class="grey--text text--darken-3" v-else>
                {{ CurrencyFormatting(totalAmount) }}
              </h3>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="3">
          <v-card class="shadow rounded-lg">
            <v-card-text class="py-6">
              <v-row align="center">
                <v-col cols="8">
                  <h4 class="text-no-wrap font-weight-medium">
                    Total transactions
                  </h4>
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-icon color="primary" size="30">mdi-sync</v-icon>
                </v-col>
              </v-row>
              <v-skeleton-loader width="50" v-if="isLoading" type="text"/>
              <h3 class="grey--text text--darken-3" v-else>
                {{ total }}
              </h3>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="3" v-if="!can('supplier')">
          <v-card class="shadow rounded-lg"
                  @click="$refs.duplicateListDialog.open('Transactions par date', 'by_time')">
            <v-card-text class="py-6">
              <v-row align="center">
                <v-col cols="8">
                  <h4 class="text-no-wrap font-weight-medium">
                    Transactions par date
                  </h4>
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-icon color="gifty" size="30">mdi-calendar-sync</v-icon>
                </v-col>
              </v-row>
              <v-skeleton-loader width="50" v-if="isLoading" type="text"/>
              <h3 class="grey--text text--darken-3 align-center d-flex justify-space-between" v-else>
                {{ double_transactions_by_time_total }}
                <v-icon small color="gifty">mdi-arrow-right</v-icon>
              </h3>
            </v-card-text>
          </v-card>
        </v-col>


        <v-col cols="12" lg="3" v-if="!can('supplier')">
          <v-card class="shadow rounded-lg" @click="$refs.duplicateListDialog.open('Transactions doublées', null)">
            <v-card-text class="py-6">
              <v-row align="center">
                <v-col cols="8">
                  <h4 class="text-no-wrap font-weight-medium">
                    Transactions doublées
                  </h4>
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-icon color="primary" size="30">mdi-sync-alert</v-icon>
                </v-col>
              </v-row>
              <v-skeleton-loader width="50" v-if="isLoading" type="text"/>
              <h3 class="grey--text text--darken-3 align-center d-flex justify-space-between" v-else>
                {{ double_transactions_total }}
                <v-icon small color="primary">mdi-arrow-right</v-icon>
              </h3>
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>

      <v-row v-if="can('admin | order_manager | support')">
        <v-col cols="12" class="text-end">

          <v-btn text
                 color="gifty"
                 class="rounded-lg"
                 to="/support/scan-history">
            <v-icon left>mdi-data-matrix-scan</v-icon>
            Historique des scans
            <v-icon right>mdi-chevron-right</v-icon>
          </v-btn>

          <v-btn text
                 class="rounded-lg"
                 color="gifty"
                 to="/refill/invitations">
            <v-icon left>mdi-account-convert</v-icon>
            Invitations
            <v-icon right>mdi-chevron-right</v-icon>
          </v-btn>

          <v-btn color="gifty"
                 class="rounded-lg ml-1"
                 depressed
                 dark
                 v-if="can('admin | order_manager')"
                 @click="exportTransaction()">
            <v-icon left>mdi-download</v-icon>
            Exporter
          </v-btn>

        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card class="rounded-lg shadow">

            <v-card-title>
              <v-chip-group v-model="filter.type" @change="fetchData">

                <v-chip value="voucher"
                        :style="{background: 'rgba(255,171,2,0.12)'}"
                        text-color="primary" filter>
                  Voucher
                  <v-icon right small color="primary">mdi-ticket</v-icon>
                </v-chip>

                <v-chip value="voucher_store_used"
                        :style="{background: 'rgba(255,171,2,0.12)'}"
                        text-color="primary"
                        filter>
                  Voucher utilisé en mag.
                  <v-icon right small color="primary">mdi-ticket</v-icon>
                </v-chip>

                <v-chip value="flexy"
                        text-color="gifty"
                        :style="{background: 'rgba(214,12,86,0.12)'}"
                        filter>
                  Flexy
                  <v-icon right small color="gifty">mdi-cellphone-wireless</v-icon>
                </v-chip>

                <v-chip value="4G"
                        text-color="info"
                        :style="{background: 'rgba(33,150,243,0.12)'}"
                        filter>
                  Carte
                  <v-icon right small color="info">mdi-card-multiple</v-icon>
                </v-chip>

                <v-chip value="sent_refill"
                        :style="{background: 'rgba(63,81,181,0.12)'}"
                        text-color="indigo" filter>
                  Solde Envoyé
                  <v-icon right small color="indigo">mdi-arrow-up-right-bold</v-icon>
                </v-chip>

                <v-chip value="received_refill"
                        text-color="success"
                        :style="{background: 'rgba(121,215,15,0.12)'}"
                        filter>
                  Solde Reçu
                  <v-icon right small color="success">mdi-arrow-down-left-bold</v-icon>
                </v-chip>

                <v-chip value="refund"
                        :style="{background: 'rgba(0,150,136,0.12)'}"
                        text-color="teal" filter>
                  Remboursée
                  <v-icon right small color="teal">mdi-arrow-left-top-bold</v-icon>
                </v-chip>

              </v-chip-group>
            </v-card-title>

            <v-divider/>

            <v-card-title>
              <div>
                <form @submit.prevent="fetchData" class="d-flex align-center">
                  <v-text-field v-model.trim="filter.keyword"
                                clearable
                                dense
                                filled
                                hide-details
                                placeholder="Rechercher..."
                                rounded
                                single-line
                                @click:clear="[filter.keyword = null,fetchData()]"
                  >

                  </v-text-field>
                  &nbsp;
                  <v-btn :disabled="!keyword"
                         color="primary"
                         depressed
                         rounded
                         type="submit"
                  >
                    <v-icon left>mdi-magnify</v-icon>
                    Recherche
                  </v-btn>
                </form>

              </div>

              <v-spacer/>

              <FilterDialog :filter="filter" @filter="[filter.page = 1, fetchData()]"/>

              <v-btn color="grey" text @click="refresh()">
                <v-icon left>mdi-refresh</v-icon>
                Mettre à jour
              </v-btn>

            </v-card-title>

            <v-divider/>

            <v-card-text v-if="isLoading">
              <v-skeleton-loader type="table"/>
            </v-card-text>


            <v-card-text v-else class="pa-0">

              <div v-if="transactions.data.length > 0">
                <v-data-table :expanded.sync="expanded" :headers="headers" :hide-default-footer="true"
                              :hide-default-header="true" :items="transactions.data" :items-per-page="filter.size"
                              :mobile-breakpoint="0" :single-expand="true" item-key="id" show-expand>
                  <template v-slot:header="{}">
                    <thead>
                    <tr>
                      <th class="text-no-wrap">ID</th>
                      <th class="text-no-wrap">Utilisateur</th>
                      <th class="text-no-wrap">Date</th>
                      <th class="text-no-wrap">Montant</th>
                      <th class="text-no-wrap">Destinataire</th>
                      <th class="text-no-wrap">Frais de service</th>
                      <th class="text-no-wrap">Promotion</th>
                      <th class="text-no-wrap">Code promo</th>
                      <th class="text-no-wrap">Total</th>
                      <th class="text-no-wrap">Débits</th>
                      <th class="text-no-wrap">Type</th>
                      <th class="text-no-wrap">Service</th>
                      <th class="text-no-wrap">Paiement API</th>
                      <th class="text-no-wrap">Mode paiement</th>
                      <th class="text-no-wrap">Action</th>
                    </tr>
                    </thead>
                  </template>

                  <template v-slot:item="{ item, expand, isExpanded }">
                    <tr>
                      <td class="text-no-wrap">{{ item.id }}</td>

                      <td>
                        <Avatar :user="item.account" :byAccount="true"/>
                      </td>

                      <td class="text-no-wrap">
                        {{ dateFormat(item.created_at) }}
                      </td>

                      <td class="text-no-wrap font-weight-medium gifty-text">
                        {{ CurrencyFormatting(item.amount) }}
                      </td>

                      <td>
                        <Avatar v-if="item.recipient_account && item.recipient_account.user"
                                :user="item.recipient_account"
                                :byAccount="true"/>
                        <span v-else>-</span>
                      </td>

                      <td class="text-no-wrap font-weight-medium gifty--text text-center">
                                                <span v-if="item.transaction_fee">
                                                    {{ CurrencyFormatting(item.transaction_fee.fee_amount) }}
                                                </span>
                        <span v-else> - </span>
                      </td>
                      <td class="text-no-wrap font-weight-medium gifty--text text-center">
                                                <span v-if="item.transaction_promotion">
                                                    {{
                                                    CurrencyFormatting(item.transaction_promotion.promotion_amount)
                                                  }}
                                                </span>
                        <span v-else> - </span>
                      </td>

                      <td class="text-no-wrap font-weight-medium gifty--text text-center">
                                                <span v-if="item.transaction_promo_code">
                                                    {{
                                                    CurrencyFormatting(item.transaction_promo_code.promo_code_amount)
                                                  }}
                                                </span>
                        <span v-else> - </span>
                      </td>

                      <td>
                        <v-chip color="success" dark small>
                          {{ CurrencyFormatting(item.total_amount) }} DZD
                        </v-chip>
                      </td>

                      <td>
                        <v-btn v-if="item.debits" class="gifty" icon small
                               @click="openDebits(item)">
                          <v-icon class="white--text" small>
                            mdi-page-next-outline
                          </v-icon>
                        </v-btn>
                      </td>

                      <td class="text-no-wrap">

                        <v-chip v-if="item.type === 'sent_refill'"
                                dark
                                small
                                color="indigo">
                          <v-icon left small>
                            mdi-arrow-up-right-bold
                          </v-icon>
                          Sode envoyé
                        </v-chip>

                        <v-chip v-if="item.type === 'received_refill'"
                                dark
                                small
                                color="success">
                          <v-icon left small>
                            mdi-arrow-down-left-bold
                          </v-icon>
                          Sode reçu
                        </v-chip>

                        <v-chip v-if="item.type === 'voucher'"
                                dark
                                small
                                color="primary">
                          <v-icon left small>
                            mdi-ticket
                          </v-icon>
                          {{ item.voucher.store_used ? "Voucher utilisé en mag." : "Voucher" }}
                        </v-chip>

                        <v-chip v-if="item.type === 'flexy'"
                                dark
                                small
                                color="gifty">
                          <v-icon left small>
                            mdi-cellphone-wireless
                          </v-icon>
                          Flexy
                        </v-chip>

                        <v-chip v-if="item.type === 'Idoom' || item.type === '4G'"
                                dark
                                small
                                color="info">
                          <v-icon left small>
                            mdi-card-multiple
                          </v-icon>
                          Idoom
                        </v-chip>

                        <v-chip v-if="item.type === 'refund'"
                                dark
                                small
                                color="teal">
                          <v-icon small left>
                            mdi-arrow-left-top-bold
                          </v-icon>
                          Remboursée
                        </v-chip>

                      </td>

                      <td class="text-no-wrap">
                        {{ item.service != null ? item.service.name : "-" }}
                      </td>

                      <td class="text-center">
                        <v-icon color="success" v-if="item.api_payment" @click="showApiPayment(item.api_payment)">
                          mdi-check-circle
                        </v-icon>
                        <v-icon color="red" v-else @click="showApiPayment(item.api_payment)">mdi-close-circle</v-icon>
                      </td>

                      <td class="text-center">

                        <img alt="" src="@/assets/carte_CIB.jpg" width="40px" v-if="item.with_e_payment"/>
                        <img alt="" src="@/assets/logo_gifty.svg" width="40px" v-else/>
                      </td>


                      <td>
                        <div class="text-no-wrap d-flex">
                          <v-btn color="success" icon @click="acceptRefill(item)" v-if="(item.type === 'sent_refill'
                                                    || item.type === 'received_refill')
                                                     && (item.refill.state === 'pending'
                                                     ||item.refill.state === 'verification' )">
                            <v-icon>mdi-check-outline</v-icon>
                          </v-btn>

                          <v-btn color="error" icon @click="cancelTransaction(item)">
                            <v-icon>mdi-close-circle-outline</v-icon>
                          </v-btn>

                          <v-btn
                              v-if="item.type === 'Idoom' || item.type === '4G' || item.type === 'voucher'"
                              icon @click="$refs.updatePhoneDialog.open(item)">
                            <v-icon>mdi-phone-return-outline</v-icon>
                          </v-btn>

                          <v-btn icon @click.prevent="[expand(!isExpanded)]">
                            <v-icon v-if="!isExpanded">
                              mdi-plus-circle-outline
                            </v-icon>
                            <v-icon v-if="isExpanded">
                              mdi-minus-circle-outline
                            </v-icon>
                          </v-btn>

                          <v-btn icon v-if="item.type === 'flexy'"
                                 @click="$refs.checkFlexyStatusDialog.open(item)">
                            <v-icon>mdi-cellphone-message</v-icon>
                          </v-btn>

                        </div>
                      </td>
                    </tr>
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <DetailsVoucher v-if="item.voucher" :item="item" class="pa-4 grey lighten-5"
                                      @resendSMS="[resendSms($event)]"/>

                      <DetailsCard v-if="item.idoom_card" :item="item" class="pa-4 grey lighten-5"
                                   @openMartchipCard="openMartchipCard($event)"
                                   @resendSMS="[resendSms($event)]"/>

                      <DetailsRefill v-if="item.refill" :item="item" class="pa-4 grey lighten-5"/>

                      <DetailsFlexy v-if="item.flexy" :item="item" class="pa-4 grey lighten-5"/>
                    </td>
                  </template>
                </v-data-table>
              </div>

              <div v-else>
                <NoResults @reload="fetchData"/>
              </div>

            </v-card-text>

            <v-divider v-if="transactions.total > 15"/>

            <v-card-actions v-if="transactions.total > 15">

              <div :style="{ width: '120px' }">
                <v-select v-model="filter.size" :items="[10, 20, 30, 50, 100]" dense hide-details
                          label="Ligne par page" outlined @change="onPageChange"></v-select>
              </div>

              <v-spacer/>

              <v-pagination v-model="filter.page" :length="Math.ceil(transactions.total / filter.size)" circle
                            total-visible="8" @input="onPageChange"></v-pagination>

            </v-card-actions>


          </v-card>
        </v-col>
      </v-row>
    </div>

    <ResendSms ref="resendSms" @refresh="fetchData"/>
    <CancelTransaction ref="cancelTransaction" @refresh="fetchData"/>
    <AcceptRefill ref="acceptRefill" @refresh="fetchData"/>
    <MartchipCard ref="martchipCard"/>
    <Debits ref="debitsModal"/>
    <UpdatePhoneDialog ref="updatePhoneDialog" @refresh="fetchData"/>
    <DuplicateListDialog ref="duplicateListDialog"/>
    <CheckFlexyStatusDialog ref="checkFlexyStatusDialog"/>
    <ApiPaymentDialog ref="apiPaymentDialog"/>

  </v-container>
</template>

<script>
import {HTTP} from "@/http-common";
import CheckFlexyStatusDialog from "./components/CheckFlexyStatusDialog.vue";
import FilterDialog from "./components/FilterDialog.vue";
import DetailsVoucher from "./components/DetailsVoucher.vue";
import DetailsCard from "./components/DetailsCard.vue";
import DetailsRefill from "./components/DetailsRefill.vue";
import DetailsFlexy from "./components/DetailsFlexy.vue";
import ResendSms from "./components/ResendSms.vue";
import ApiPaymentDialog from "./components/ApiPaymentDialog.vue";
import DuplicateListDialog from "./components/DuplicateListDialog.vue";
import CancelTransaction from "./components/CancelTransaction.vue";
import AcceptRefill from "./components/AcceptRefill.vue";
import MartchipCard from "../cards/components/MartchipCard.vue";
import Debits from "./components/Debits.vue";
import UpdatePhoneDialog from "./components/UpdatePhoneDialog.vue";
import NoResults from "@/components/NoResults";
import moment from "moment";
import Avatar from "@/components/Avatar";

export default {
  components: {
    Avatar,
    ApiPaymentDialog,
    CheckFlexyStatusDialog,
    FilterDialog,
    DetailsVoucher,
    ResendSms,
    DetailsCard,
    DetailsRefill,
    DetailsFlexy,
    CancelTransaction,
    AcceptRefill,
    DuplicateListDialog,
    MartchipCard,
    UpdatePhoneDialog,
    NoResults,
    Debits,
  },
  data() {
    return {
      totalAmountLoading: false,
      isLoading: true,
      double_transactions_total: 0,
      double_transactions_by_time_total: 0,
      total: 0,

      totalAmount: 0,

      filter: {
        size: 10,
        page: 1,

        current: 1,
        total: 0,

        startDate: null,
        endDate: null,
        keyword: null,
        type: '',
        types: [],
        with_sms: -1,

        // amount
        amount: null,
        phone: null,

        // for vouchers
        suppliers: [],

        // services
        services: [],

        // for refills
        // emetters
        emetters: [],
        // receptters
        receptters: [],

        // users
        users: [],

        accounts: [],
        // martship status
        status: [],

        no_sms: false,

        payment_mode: ""
      },
      transactions: [],

      // Headers
      expanded: [],
      singleExpand: true,
      headers: [
        {text: "ID", value: "id"},
        {text: "Nom", value: "account.user.name"},
        {text: "Date", value: "created_at"},
        {text: "Montant", value: "amount"},
        {text: "Type", value: "type"},
        {text: "Frais", value: "service.name"},
        {text: "Promotion", value: "service.name"},
        {text: "Promotion Code", value: "service.name"},
        {text: "Total", value: "service.name"},
        {text: "Debits", value: "service.name"},
        {text: "Service", value: "service.name"},
        {text: "Paiement API", value: "api_payment"},
        {text: "Mode paiement", value: "service.name"},
        {text: "Actions", value: ""},
      ],
    };
  },
  methods: {
    showApiPayment(apiPayment) {
      this.$refs.apiPaymentDialog.open(apiPayment)
    },
    fetchData() {
      this.getTotalAmount();
      this.isLoading = true;
      HTTP.get("/v1/support/transactions", {
        params: {...this.filter}
      }).then((res) => {
        this.transactions = res.data.data;
        this.total = this.transactions.total;
        this.double_transactions_total = res.data.double_transactions_total;
        this.double_transactions_by_time_total = res.data.double_transactions_by_time_total;
        this.isLoading = false;
      }).catch((e) => {
        console.log(e);
        this.isLoading = false;
      });
    },
    onPageChange() {
      this.fetchData();
    },
    resendSms(item) {
      this.$refs.resendSms.open(item);
    },
    refresh() {
      this.resetFilter();
      this.fetchData();
    },
    getTotalAmount() {
      this.totalAmountLoading = true;
      HTTP.get("/v1/support/transactions/totals", {
        params: {...this.filter,},
      }).then((res) => {
        this.totalAmount = res.data.data;
        this.totalAmountLoading = false;
      }).catch((err) => {
        this.totalAmountLoading = false;
        console.log(err);
      });
    },
    filterByType(types) {
      let old_types = this.filter.types;
      this.resetFilter();

      if (Array.isArray(types)) {
        types.forEach((el) => {
          if (old_types.includes(el)) {
            old_types = old_types.filter((e) => e !== el);
          } else {
            old_types.push(el);
          }
        });
      } else {
        if (old_types.includes(types)) {
          old_types = old_types.filter((e) => e !== types);
        } else {
          old_types.push(types);
        }
      }

      this.filter.types = old_types;
      this.filter.page = 1;
      this.fetchData();
    },
    resetFilter() {
      this.filter = {
        size: 10,
        page: 1,
        current: 1,
        total: 0,
        startDate: null,
        endDate: null,
        keyword: null,
        types: [],
        with_sms: -1,
        no_sms: false,
        payment_mode: ""
      };
    },
    showAccount(account_id) {
      let route = this.$router.resolve({path: "/users/" + account_id});
      window.open(route.href, "_blank");
    },
    cancelTransaction(transaction) {
      this.$refs.cancelTransaction.open(transaction);
    },
    acceptRefill(transaction) {
      this.$refs.acceptRefill.open(transaction);
    },
    can_cancel(item) {
      if (item.type === "voucher") {
        return true;
      }
      if (item.type === "4G" || item.type === "Idoom") {
        return true;
      }
      if (item.type === "flexy") {
        if (item.flexy.martship_state !== "OK" && item.flexy.martship_state !== "REFUNDED") {
          return true;
        }
      }
      return false;
    },

    openMartchipCard(item) {
      this.$refs.martchipCard.open(item);
    },
    openDebits(item) {
      if (item.debits) {
        this.$refs.debitsModal.open(item.debits);
      }
    },
    exportTransaction() {
      let baseUrl = process.env.VUE_APP_FILE_URL;
      let token = this.$store.state.user.access_token;
      let userId = this.$store.state.user.user.id;
      let url = baseUrl + "/api/v1/support/export?token=" + token + "&user_id=" + userId;
      url += "&filter=" + encodeURIComponent(JSON.stringify(this.filter));
      window.open(url, '_blanc')
    },
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY, HH:mm:ss');
    }
  },
  computed: {
    keyword() {
      return this.filter.keyword;
    },
  },
  watch: {
    keyword() {
      //this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>